import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ai--global-assistant-panels"
export default class extends Controller {
  static targets = ["thread", "suggestions", "history", "tips", "container"]

  threadTarget: HTMLElement
  suggestionsTarget: HTMLElement
  historyTarget: HTMLElement
  tipsTarget: HTMLElement
  containerTarget: HTMLElement

  panels: HTMLElement[]
  activePanel: HTMLElement
  positions: Map<HTMLElement, number>

  connect() {
    this.panels = [this.threadTarget, this.suggestionsTarget, this.historyTarget, this.tipsTarget]
    this.activePanel = this.panels.find((p) => !p.classList.contains("hidden"))

    this.positions = new Map([
      [this.threadTarget, 0],
      [this.suggestionsTarget, 0],
      [this.historyTarget, 0],
      [this.tipsTarget, 0],
    ])
  }

  togglePanel = (panel: HTMLElement) => {
    // Save the scroll position from the current panel before toggling
    this.positions.set(this.activePanel, this.containerTarget.scrollTop)

    // Toggle the panel
    this.activePanel = panel
    this.panels.forEach((p) => {
      if (p === panel) {
        p.classList.remove("hidden")
      } else {
        p.classList.add("hidden")
      }
    })

    // Restore the scroll position for the new panel
    this.containerTarget.scrollTop = this.positions.get(panel)
  }

  showThread = () => {
    this.togglePanel(this.threadTarget)
  }

  toggleThread = () => {
    if (this.hasActiveThread()) {
      this.togglePanel(this.threadTarget)
    } else {
      this.togglePanel(this.suggestionsTarget)
    }
  }

  toggleHistory = () => {
    this.togglePanel(this.historyTarget)
  }

  toggleTips = () => {
    this.togglePanel(this.tipsTarget)
  }

  toggleSuggestions = (e) => {
    if (this.hasActiveThread()) {
      this.togglePanel(this.suggestionsTarget)
    } else {
      e.preventDefault()
      return
    }
  }

  hasActiveThread = () => {
    return this.threadTarget.querySelectorAll("turbo-frame").length > 0
  }
}
