import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../../utils"

export default class extends Controller {
  static targets = ["sidecar", "expandButton", "collapseButton"]

  connect() {
    // Show sidecar and collapse button by default, hide expand button
    this.showSidecar()
  }

  hideSidecar() {
    hide(this.sidecarTarget)
    show(this.expandButtonTarget)
    hide(this.collapseButtonTarget)
  }

  showSidecar() {
    show(this.sidecarTarget)
    hide(this.expandButtonTarget)
    show(this.collapseButtonTarget)
  }
}
